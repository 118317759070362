<template>
  <div id="invoicing-tiss">
    <br/>
    <h3>Configurações versão e numeração</h3>
    <div class="config">
      <b-row>
        <b-col cols="4">
          <label for="xmlVersion">Versão TISS</label>
          <v-autocomplete
            id="xmlVersion"
            :options="xmlVersions"
            placeholder="Selecionar"
            v-model="tiss.XML_version"
          />
          <div
            v-if="validated && !tiss.XML_version"
            class="custom-invalid-feedback"
          >
            Campo obrigatório
          </div>
        </b-col>

        <b-col cols="2">
          <label for="current_batch_number">Nº do lote atual</label>
          <b-input
            id="current_batch_number"
            autocomplete="off"
            type="number"
            class="no-arrow"
            placeholder="Descrever"
            v-model="tiss.current_batch_number"
            @input="debounceBatchNumber"
          />
          <div
            v-if="validated && !tiss.current_batch_number"
            class="custom-invalid-feedback"
          >
            Campo obrigatório
          </div>
          <div class="custom-invalid-feedback" v-if="batchNumberExist">
            O número do lote informado já existe
          </div>
        </b-col>

        <b-col cols="2">
          <label for="current_invoice_number">Nº da fatura atual</label>
          <b-input
            autocomplete="off"
            id="current_invoice_number"
            type="number"
            class="no-arrow"
            placeholder="Descrever"
            min="0"
            v-model="tiss.current_invoice_number"
            @input="debounceInvoiceNumber"
          />
          <div
            v-if="validated && !tiss.current_invoice_number"
            class="custom-invalid-feedback"
          >
            Campo obrigatório
          </div>
          <div class="custom-invalid-feedback" v-if="invoiceNumberExist">
            O número da fatura informado já existe
          </div>
        </b-col>

        <b-col cols="2">
          <label for="current_guide_number">Nº da guia atual</label>
          <b-input
            id="current_guide_number"
            autocomplete="off"
            type="number"
            placeholder="Descrever"
            v-model="tiss.current_guide_number"
            @input="debounceGuideNumber($event)"
          />
          <div
            v-if="validated && !tiss.current_guide_number"
            class="custom-invalid-feedback"
          >
            Campo obrigatório
          </div>
          <div v-if="guideNumberExist" class="custom-invalid-feedback">
            O número da guia informado já existe
          </div>
        </b-col>

        <b-col cols="2">
          <label for="max_batches_per_guide">Nº máx. de guias por lote</label>
          <b-input
            autocomplete="off"
            id="max_batches_per_guide"
            min="1"
            type="number"
            placeholder="Descrever"
            v-model="tiss.max_batches_per_guide"
          />
          <div
            v-if="validated && !tiss.max_batches_per_guide"
            class="custom-invalid-feedback"
          >
            Campo obrigatório
          </div>
        </b-col>
      </b-row>
    </div>
    <hr />
  </div>
</template>

<script>
import Autocomplete from '@/components/Autocomplete'
import debounce from 'lodash/debounce'

export default {
  props: {
    tiss: Object,
    validated: Boolean
  },
  components: { 'v-autocomplete': Autocomplete },
  async created() {
    this.debounceBatchNumber = debounce(this.getBatchNumberExist, 500)
    this.debounceInvoiceNumber = debounce(this.getInvoiceNumberExist, 500)
    this.debounceGuideNumber = debounce(this.getGuideNumberExist, 500)

    const isLoading = this.$loading.show()
    await this.getBatchNumberExist(this.tiss.current_batch_number)
    await this.getInvoiceNumberExist(this.tiss.current_invoice_number)
    await this.getGuideNumberExist(this.tiss.current_guide_number)

    isLoading.hide()
  },
  data() {
    const clinic = JSON.parse(localStorage.getItem('clinic'))
    return {
      debounceBatchNumber: Function,
      debounceInvoiceNumber: Function,
      batchNumberExist: false,
      invoiceNumberExist: false,
      guideNumberExist: false,
      date: String,
      clinic_id: clinic.id,
      xmlVersions: [
        { label: '3.03.02', value: '3.03.02' },
        { label: '3.03.03', value: '3.03.03' },
        { label: '3.04.01', value: '3.04.01' },
        { label: '3.05.00', value: '3.05.00' },
        { label: '4.00.00', value: '4.00.00' },
        { label: '4.00.01', value: '4.00.01' },
        { label: '4.01.00', value: '4.01.00' },
      ],
    }
  },
  methods: {
    async getBatchNumberExist(value) {
      if (!this.tiss.clinic_health_plan_id) return
      try {
        const response = await this.api.batchNumberExists(
          value,
          this.tiss.clinic_health_plan_id
        )
        this.batchNumberExist = response.data
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async getInvoiceNumberExist(value) {
      if (!this.tiss.clinic_health_plan_id) return
      try {
        const response = await this.api.invoiceNumberExist(
          value,
          this.tiss.clinic_health_plan_id
        )
        this.invoiceNumberExist = response.data
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    async getCurrentGuideNumber() {
      
    },
    async getGuideNumberExist(value) {
      if (!this.tiss.clinic_health_plan_id) return
      try {
        const { data } = await this.api.guideNumberExist(
          value,
          this.tiss.clinic_health_plan_id
        )
        this.guideNumberExist = data.exists
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
  },
  watch: {
    tiss: function () {
      this.$emit('updateTiss', this.tiss)
    },
    guideNumberExist: {
      handler(value) {
        const result = Object.values(value).some(item => item)
        this.$emit('invalidSettingsNumbers', result)
      },
      deep: true
    },
    batchNumberExist: {
      handler(value) {
        this.$emit('invalidSettingsNumbers', value)
      },
      deep: true
    },
    invoiceNumberExist: {
      handler(value) {
        this.$emit('invalidSettingsNumbers', value)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
#invoicing-tiss {
  width: 100%;

  h3 {
    font-family: Red Hat Display;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: left;
    color: var(--blue-500);
  }

  .config {
    margin-top: 25px;
    width: 98%;
    padding: 0px;

    label {
      font-family: Nunito Sans;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: var(--dark-blue);
    }

    & > div {
      margin-top: 15px;
    }

    .date-picker {
      border-radius: 8px;
      border: 1px solid var(--neutral-300);

      .date-input {
        width: 100%;
      }
    }

    .multiple {
      border: 1px solid var(--neutral-300);
      border-radius: 8px;
      margin: 4px 0 20px 0;
      height: 38px !important;
      box-sizing: border-box;

      .selectedGuides {
        height: 150%;
        overflow: hidden !important;
      }

      .multiselect__tags {
        height: 100%;
      }
    }
  }

  .checkbox-title {
    margin: 35px 0;
  }

  .info-checkboxes {
    border-radius: 16px;
    border: 1px solid var(--neutral-300);
    padding: 15px;

    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 15px 2px;

      label {
        font-family: Nunito Sans;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 10px;
        margin-bottom: 0rem !important;
        color: var(--type-active);

        b {
          font-weight: 800 !important;
        }
      }
    }
  }

  .no-arrow::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  .no-arrow {
    -moz-appearance: textfield;
    appearance: textfield;
  }
}
</style>
